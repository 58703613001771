<template>
  <div>
    <template v-if="!param.vendorFlag">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="height"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :key="tab.key"
            :riskReduce.sync="riskReduce"
            :param="param"
            :height="height"
            @research="research"
          />
        </template>
      </c-tab>
    </template>
    <template v-else>
      <component
        :is="imprComponent"
        :riskReduce.sync="riskReduce"
        :param="param"
        :height="height"
        @research="research"
      />
    </template>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskTab',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      tab: 'risk',
      imprComponent: () => import(`${'@/pages/ram/4m/risk/4mImpr.vue'}`),
      riskReduce: {
        imprs: [],
        riskbooks: [],
      }
    };
  },
  watch: {
    'param.searchRiskReduce.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
  },
  computed: {
    tabItems() {
      let imprComponent = null;
      let riskComponent = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        imprComponent = () => import(`${'@/pages/ram/hazop/risk/hazopImpr.vue'}`)
        riskComponent = () => import(`${'@/pages/ram/hazop/risk/hazopRiskRegister.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M
        imprComponent = () => import(`${'@/pages/ram/4m/risk/4mImpr.vue'}`)
        riskComponent = () => import(`${'@/pages/ram/4m/risk/4mRiskRegister.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM
        imprComponent = () => import(`${'@/pages/ram/charm/risk/charmImpr.vue'}`)
        riskComponent = () => import(`${'@/pages/ram/charm/risk/charmRiskRegister.vue'}`)
      } 
      return [
      { key: uid(), name: 'risk', icon: 'report_gmailerrorred', label: 'LBL0001171', component: riskComponent },
        { key: uid(), name: 'impr', icon: 'construction', label: '개선대상', component: imprComponent },
      ];
    },
    listUrl() {
      let url = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        url = selectConfig.ram.hazop.riskReduce.list.url;
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M
        url = selectConfig.ram.fm.riskReduce.list.url;
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM
        url = selectConfig.ram.charm.riskReduce.list.url;
      } 
      return url;
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    research() {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: 'riskReduce',
        }
      })
    },
  }
};
</script>
